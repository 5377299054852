import axios from "axios";

const apiToken = "oh.familyDashboard.zDYxw0bxV7Zl0Wt4pcERTLhHVSEYTvhPg8pRdWqcZzz7Jkt5YZ6Yw9H7tk6ZnU27CReTjLD3wvxoIb3fSA";

const config = {
    headers: {
        Authorization: `Bearer ${apiToken}`,
        //accept: 'application/json',
        'Content-Type': 'text/plain'
    }
};

export const setOpenHabItemState = (item, state, onSuccess) => {
    const url = "http://192.168.1.115:8080/rest/items/" + item;

    axios.post(url, state, config)
        .then(response => {
            console.log("#############", response);
            // response.status === 200 )=> OK
            onSuccess && onSuccess(response);
        })
        .catch(error => {
            console.error("setOpenHabItemState", error);
        })
};

export const getOpenHabItemState = (item, onSuccess) => {
    const url = "http://192.168.1.115:8080/rest/items/" + item + "/state";
    //const url = "http://192.168.1.115:8080/rest/items/HueLampeOpa_Helligkeit/state";

    console.log("getOpenHabItemState", url);

    axios.get(url, config)
        .then(response => {
            console.log("#############", response);
            // response.status === 200 )=> OK
            onSuccess && onSuccess(response.data);
        })
        .catch(error => {
            console.error("getOpenHabItemState", error);
        })
}