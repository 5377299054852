import axios from 'axios';

import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from '@mui/material/Modal';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { loadGoogleCalendarData } from '../Actions/googleCalendar';


const tage = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

const localizer = momentLocalizer(moment)

const GoogleStundenplan = (props) => {

    const [events, setEvents] = useState(undefined);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.calendarId && loadCalendarData(props.calendarId);
    }, [props.calendarId]);


    const getStartOfWeek = (date) => {
        const dStart = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
        const startString = encodeURIComponent(dStart.toISOString());
        return startString;
    }

    const getEndOfWeek = (date) => {
        const dStart = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (8 - date.getDay()));
        const startString = encodeURIComponent(dStart.toISOString());
        return startString;
    }

    const getReferenzTag = () => {
        // Referenztag Mo-Fr heute, sonst der nächsten Montag
        // unserer Woche startet Montag!    
        var tag = new Date();
        console.log("tag", tag.getDay());
        // Samstag
        if (tag.getDay() == 6) {
            tag = new Date(tag.setDate(tag.getDate() + 2));
        }
        // Sonntag
        if (tag.getDay() == 0) {
            tag = new Date(tag.setDate(tag.getDate() + 1));
        }

        return new Date(tag.getFullYear(), tag.getMonth(), tag.getDate());
    }

    const getNextEvent = () => {
        var tag = getReferenzTag();
        console.log("getNextEvent", tag);

        // den ersten und den letzten Eintrag an diesem Tag suchen
        const termineHeute = events.filter(ev => ev.start.getDate() === tag.getDate()).sort((a, b) => a.start - b.start);
        const first = termineHeute[0];
        const last = termineHeute[termineHeute.length - 1];

        // wenn das Ende des letzten Eintrags + 2 Stunden vorbei ist, dann der erste Eintrag des nächsten TAges
        if (last.end < new Date().addHours(-2)) {
            const morgen = new Date(tag.setDate(tag.getDate() + 1));
            const termineMorgen = events.filter(ev => ev.start.getDate() === morgen.getDate()).sort((a, b) => a.start - b.start)
            const firstMorgen = termineMorgen[0];
            return tage[firstMorgen.start.getDay()] + " " + firstMorgen.start.getHours() + ":" + getMinutes(firstMorgen.start) + " " + firstMorgen.title;
        }

        // wenn der erste Eintrag schon vorbei ist, wählen wir den letzten Eintrag des Tages
        if (first.start < new Date()) {
            return tage[last.end.getDay()] + " " + last.end.getHours() + ":" + getMinutes(last.end) + " Ende " + last.title;
        }

        // sonst heute
        return tage[first.start.getDay()] + " " + first.start.getHours() + ":" + getMinutes(first.start) + " " + first.title;
    }

    const loadCalendarData = (calendarId) => {
        var tag = getReferenzTag();

        loadGoogleCalendarData(calendarId, getStartOfWeek(tag), getEndOfWeek(tag), (ev) => {
            ev && setEvents(mapEvents(ev));
        });

    }

    const mapEvents = (events) => {
        var result = events.map(ev => {
            return {
                id: ev.id,
                start: new Date(ev.start.dateTime),
                end: new Date(ev.end.dateTime),
                title: ev.summary
            }
        });
        return result;
    }

    const CustomEvent = (event) => {
        return (
            <span>
                {event.event.start && event.event.start.getHours()}:{event.event.start && getMinutes(event.event.start)}
                <strong> {event.title} </strong>
            </span>
        )
    }

    const getMinutes = (date) => {
        const minutes = date.getMinutes();
        return minutes < 10 ? '0' + minutes : minutes;
    }

    Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + (h * 60 * 60 * 1000));
        return this;
    }

    return (
        <div>
            <Button color='primary' variant='contained' sx={{ width: '100%', height: '110px' }} onClick={() => setOpen(true)}>
                <div>
                <Typography variant="h6" component="div">
                    {props.titel}
                </Typography>
                <Typography variant='body2' component="div">
                    {events && getNextEvent()}
                </Typography>
                </div>
            </Button>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <div style={{ margin: '30px', padding: '10px', borderRadius: '5px', backgroundColor: '#fff' }}>
                    <Calendar
                        date={getReferenzTag()}
                        view='week'
                        scrollToTime={getReferenzTag().addHours(6)}
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        components={
                            {
                                event: CustomEvent,
                            }
                        }
                    />
                </div>
            </Modal>
        </div >
    )
}

export default GoogleStundenplan;