import logo from './logo.svg';
import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Dashboard from './Dashboard';


function App() {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
