import axios from "axios";


const apiKey = "AIzaSyCUkmD7E39BU4ss8IGRt3n3VARRurnKEOE";


export const loadGoogleCalendarData = (calendarId, start, end, onSuccess) => {

    const service = "https://www.googleapis.com/calendar/v3/calendars/" +
        calendarId +
        "/events?singleEvents=true&timeMax=" + end +
        "&timeMin=" + start + "&key=" + apiKey;

    axios.get(service)
        .then(response => {
            onSuccess && onSuccess(response.data.items);
        })
        .catch(error => {
            console.error("loadCalendarData", error);
        });
}
