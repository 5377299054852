import React, { useEffect, useState } from "react";
import { getOpenHabItemState, setOpenHabItemState } from "../Actions/onpenHab";
import { Button } from "@mui/material";

const OpenHabOnOff = (props) => {


    const [itemState, setItemState] = useState(undefined);

    useEffect(() => {
        getItemtatus();
    }, []);

    const onButtonClick = () => {
        if (props.itemName) {
            const newState = itemState === "ON" ? "OFF" : "ON";
            setOpenHabItemState(props.itemName, newState, (response) => {
                console.log("onLichtAnClick", response);
                getItemtatus();
            });
        }
    }

    const getItemtatus = () => {
        getOpenHabItemState(props.itemName, (s) => {
            setItemState(s > 1 ? "ON" : "OFF");
        });
    }

    return (
        <div>

            <Button
                onClick={onButtonClick}
                variant={itemState == "ON" ? "contained" : "outlined"}
                size="large">
                Licht toggle
            </Button>
            {itemState}
        </div>
    )
}

export default OpenHabOnOff;