import React from 'react';

import { Box, Button, Paper, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GoogleStundenplan from './Controls/GoogleStundenplan';
import { setOpenHabItemState } from './Actions/onpenHab';
import OpenHabOnOff from './Controls/OpenHabOnOff';

const calendarIdStundenplanElias = "d7c862ae861116487a906ade01517070cd39eef9d79a230d5a371b1f3f3df3ad@group.calendar.google.com";
const calendarIdStundenplanNoah = "4e2cee9c1d74f687ce900c171cfa1917404033eab4d6f0edf761eb351bb4c8f6@group.calendar.google.com";

const Dashboard = () => {

    const [selectedTab, setSelectedTab] = React.useState(undefined);

    const handleTabChange = (event, newValue) => {
        console.log("handleTabChange", newValue);
        setSelectedTab(newValue);
    }




    return (
        <Box sx={{ flexGrow: 1, height: '100%' }}>
            <Grid container spacing={2} sx={{ height: 'calc( 100% - 60px )' }}>
                <Grid size={6}>
                    <OpenHabOnOff itemName="HueLampeOpa_Helligkeit"/>
                </Grid>
                <Grid size={6}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item size={3}>
                            <GoogleStundenplan calendarId={calendarIdStundenplanElias} titel={"Elias"} />
                        </Grid>
                        <Grid item size={3}>
                            <GoogleStundenplan calendarId={calendarIdStundenplanNoah} titel={"Noah"} />
                        </Grid>
                    </Grid>
                    </Box>
                    
                </Grid>
            </Grid>
        </Box>
    );
}

export default Dashboard;